import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
require('videojs-youtube');
require("videojs-resolution-switcher-vjs7/videojs-resolution-switcher.js");
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import VueSocketIO from 'vue-socket.io'
import socketio from 'socket.io-client';
import router from './router'
import { createPinia, PiniaVuePlugin } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueCompositionAPI from '@vue/composition-api'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import videojsPlaylist from 'videojs-playlist'
import videojs from 'video.js'
import VueCookies from 'vue-cookies'

window.videojs = videojs
videojs.registerPlugin('videojs-playlist', videojsPlaylist);

export const SocketInstance = socketio(process.env.VUE_APP_SOCKET);

Vue.use(PiniaVuePlugin)

Vue.use(VueCookies, { expires: '7d'})

Vue.use(VueCompositionAPI)

const pinia = createPinia().use(piniaPluginPersistedstate)

Vue.use(BootstrapVue)

Vue.config.productionTip = false

Vue.use(VueVideoPlayer);

Vue.use(new VueSocketIO({
  debug: true,
  connection: SocketInstance
}))

Vue.use(IconsPlugin)

new Vue({
  render: h => h(App),
  pinia,
  router
}).$mount('#app')



