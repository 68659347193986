<template>
  <div id="app">
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
   
  }
}
</script>

<style>
@import '../node_modules/vue-custom-scrollbar/dist/vueScrollbar.css';
html, body{

  padding: 0;
  margin: 0;
  
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  
 

}
.ps .ps__rail-x.ps--clicking,.ps,.ps .ps__rail-x:hover,.ps .ps__rail-y.ps--clicking,.ps .ps__rail-y:hover{
  background-color:#20223f;
  opacity:1;
  }
.ps:hover>.ps__rail-y{
  opacity: 0;
}
.ps--scrolling-x>.ps__rail-x{
  opacity: 1;
}
</style>
